<template>
	<div v-if="planningAndInvoicesData">    
		<div class="row" v-if="provider.isVatExempt">
			<h5 class="mb-4">
				{{ PROVIDER.Portfolio.CourseEdit.InvestmentTab.InvoiceItemTitle }}
			</h5> 
			<div class="col-12">
				<div>
					<p class="u-fw-semi-bold">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatEnable }}</p>
					<recess-toggle-input 
						:value="vatExemption.value"
						name="name" 
						size="small" 
						:label-text="vatExemption.labelText"
						class="qa-toggle-vat-exemption"
						@input="(newValue) => enableInvoiceVat(newValue)" />
				</div>
			</div>
		</div>
		<div class="row" v-if="isVatExempt">
			<div class="col-2">
				<recess-input
					:value="planningAndInvoicesData.croho"
					:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.CentralRegister.Croho.Label"
					class="qa-course-diploma-croho" 
					@input="(value) => $emit('setVatValue', {key: 'croho', value})"
				/>
			</div>
			<div class="col-2">
				<recess-input
					:value="planningAndInvoicesData.crebo"
					:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.CentralRegister.Crebo.Label"
					class="qa-course-diploma-crebo" 
					@input="(value) => $emit('setVatValue', {key: 'crebo', value})"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h5>
					{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceSubTitle }}
				</h5>
			</div>
		</div>  
		<div v-for="(invoiceItem, invoiceIndex) in invoiceItems" class="qa-invoice-item"> 
			<div v-if="invoiceItem.isOptional" class="row">
				<div class="col-3">
					<h5>Optioneel</h5>
				</div>
			</div> 
			<div class="row">
				<div class="col-6"> 
					<!-- Cost types -->
					<recess-multi-select
						:selected-options="invoiceItem.costType"
						:options="costTypes"
						:search-input="true"
						:single-select="true"
						:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.CostType.Label"
						:placeholder="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.CostType.Placeholder"
						:error-message="costTypeError(invoiceIndex)"
						:class="`qa-course-cost-type-${invoiceIndex}`" 
						@input="(newValue) => onCostTypeChange(invoiceIndex, newValue)"
					/>
				</div>

				<div class="col-6 d-flex justify-content-end"> 
					<div class="d-flex align-items-center" v-if="invoiceItems && !alwaysDelete && invoiceItems.length > 1">
						<h6 class="m-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Delete }}</h6>
						<span
							:class="`ml-4 c-icon c-icon__bin qa-remove-invoice-item-button-${invoiceIndex}`"
							:data-test="`remove-invoice-item-button-${invoiceIndex}`"
							@click="removeInvoiceItem(invoiceIndex)"
						>
						</span>
					</div> 
					<div class="d-flex align-items-center" v-if="invoiceItems && alwaysDelete && invoiceItems.length > 0">
						<h6 class="m-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Delete }}</h6>
						<span
							:class="`ml-4 c-icon c-icon__bin qa-remove-invoice-item-button-${invoiceIndex}`"
							:data-test="`remove-invoice-item-button-${invoiceIndex}`"
							@click="removeInvoiceItem(invoiceIndex)"
						>
						</span>
					</div> 
				</div>
			</div>

			<div class="row">
				<div class="col-3">
					<!-- Price -->
					<recess-numeric-input
						v-model="invoiceItem.priceBeforeTax"
						:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.PriceBeforeTax.Label"
						:placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.PriceBeforeTax.Placeholder"
						:error-message="priceBeforeTaxError(invoiceIndex)"
						:class="`qa-course-price-before-tax-${invoiceIndex}`"
						@input="(newValue) => onPriceWithoutBTWChange(invoiceIndex, invoiceItem)"
					/>
				</div>

				<div class="col-3">
					<!-- Country: default value is NL-->
					<recess-multi-select
						:selected-options="invoiceItem.vatCountry"
						:options="countryOptions"
						:single-select="true"
						:search-input="true"
						:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatCountry.Label"
						:placeholder="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatCountry.Placeholder"
						:error-message="vatCountryError(invoiceIndex)"
						:class="`qa-course-vat-country-${invoiceIndex}`"
						@input="(newValue) => populateVatPercentages(invoiceIndex, invoiceItem, newValue)"
					/>
				</div> 

				<div  class="col-2" v-if="enableVatFields"> 
					<!-- show vatPercentage as dropdown if vatPercentageOptions is not null-->
					<recess-multi-select
						v-if="invoiceItem.displayVatPercentageOptions"
						:selected-options="invoiceItem.vatPercentage"
						:options="invoiceItem.vatPercentageOptions"
						:single-select="true"
						:label-text="`${PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Label}${isManualOrApiCourse ? '*' : ''}`"
						:select-options-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.SelectText"
						:error-message="vatPercentageError(invoiceIndex)"
						:class="`qa-course-vat-percentage-dropdown-${invoiceIndex}`"
						@input="(newValue) => onVatPercentageChange(invoiceItem, newValue, invoiceIndex)"
					/>

					<!-- show vatPercentage as input field if vatPercentageOptions is null -->
					<recess-input
						v-if="!invoiceItem.displayVatPercentageOptions"
						:value="invoiceItem.vatPercentage"
						:label-text="`${PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Label}${isManualOrApiCourse ? '(%) *' : '(%)'}`"
						:placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Placeholder"
						:error-message="vatPercentageError(invoiceIndex)"
						:class="`qa-course-vat-percentage-input-${invoiceIndex}`" 
						@input="(newValue) => onVatPercentageChange(invoiceItem, newValue, invoiceIndex)"
					/>
				</div>
				<div class="col-2 d-flex text-right flex-column qa-invoice-vat-amount-value">
					<div class="c-recess-input__label p-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatAmount.Label }}</div>
					<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ Number(invoiceItem.vatAmount) | currency }}</p>
				</div>

				<div class="col-2 d-flex text-right flex-column qa-invoice-price-with-tax-value">
					<div class="c-recess-input__label p-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.PriceWithTax.Label }}</div>
					<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ setPriceIncludingTaxPerInvoice(invoiceItem) | currency }}</p>
				</div>
			</div> 

			<div class="row" v-if="isVatExempt">
				<div class="col-3">
					<!-- vatExemptAmount -->
					<recess-numeric-input
						v-model="invoiceItem.vatExemptAmount"
						:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatExemptAmount.Label"
						:placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatExemptAmount.Placeholder"
						:error-message="vatExemptAmountError(invoiceIndex)"
						:class="`qa-vat-exempt-amount-${invoiceIndex}`" 
						@input="(newValue) => onVatExemptAmountChange(invoiceIndex)"
					/>
				</div>

				<div class="col-3">
					<!-- vatExemptAmountVAT -->
					<recess-numeric-input
						v-model="invoiceItem.vatExemptAmountVAT"
						:label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatExemptAmountVAT.Label"
						:placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatExemptAmountVAT.Placeholder"
						:error-message="vatExemptAmountVatError(invoiceIndex)"
						:class="`qa-vat-exempt-amount-vat-${invoiceIndex}`"
						@input="(newValue) => onVatExemptAmountVatChange(invoiceIndex)"
					/>
				</div>
				<div class="col-2 offset-2 d-flex justify-content-end">
					<div class="qa-invoice-vat-exempt-amount-vat-value">
						<div class="c-recess-input__label p-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatAmount.Label }}</div>
						<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ Number(invoiceItem.vatExemptAmountVAT) | currency }}</p>
					</div>
				</div>
				<div class="col-2 d-flex justify-content-end">
					<div class="qa-invoice-price-excluding-tax-per-invoice-value">
						<div class="c-recess-input__label p-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.TotalTaxes }}</div>
						<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ setPriceExcludingTaxPerInvoice(invoiceItem) | currency }}</p>
					</div>
				</div>
			</div>

			<recess-divider variant="medium" show-line v-if="invoiceItems.length > 1" /> 
		</div>

		<!-- add invoice button -->
		<div class="d-flex justify-content-end">
			<button-component
				type="button"
				:back-label="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.AddInvoiceItemButton"
				icon-font="add"
				:button-class="`m-0 c-button--variant4 qa-add-invoice-item-button`"
				data-test="add-invoice-item-button"
				@click.native.prevent="addInvoice()"
			/>
		</div>

		<div class="mt-5">
			<div>
				<div class="d-flex justify-content-between align-items-center qa-invoice-total-before-tax">
					<h4 class="mb-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.TotalPricesBeforeTaxTitle }}</h4>
					<h4 class="d-flex align-items-center mb-0">{{ getTotalPricesBeforeTax | currency }}</h4>
				</div>
				<recess-divider variant="small" show-line />
			</div>

			<div>
				<div class="d-flex justify-content-between align-items-center qa-invoice-total-including-tax">
					<h4 class="mb-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.TotalPricesInludingTaxTitle }}</h4>
					<h4 class="d-flex align-items-center mb-0">{{ getTotalPricesInludingTax | currency }}</h4>
				</div>
				<recess-divider variant="small" show-line />
			</div>

			<div class="mt-4" v-if="provider.isVatExempt && isVatExempt">
				<div class="d-flex justify-content-between align-items-center qa-invoice-total-taxes">
					<h4 class="mb-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.TotalTaxesVatExempt }}</h4>
					<h4 class="d-flex align-items-center mb-0">{{ getTotalTaxes | currency }}</h4>
				</div>
				<recess-divider variant="small" show-line />
			</div>
		</div>
		 
		 
	</div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, between, minValue } from 'vuelidate/lib/validators' 
import { isRequiredErrorMessage, requiredError, priceBeforeTaxMinValue,
	vatPercentageMinMaxValue, vatExemptAmountMinValue, vatExemptAmountVatMinValue } from '@/../../shared/constants/validationMessageHelper.js'

import ButtonComponent from '@/components/atoms/ButtonComponent'

import { emptyInvoiceItem } from '../courseEditHelpers' 

import { getEnumList, getItems, getItemById } from '@/../../shared/api/SharedClient'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json' 

export default {
	name: 'InvoiceItem', 
	components: {
		ButtonComponent
	},
	mixins: [validationMixin], 
	props: {
		alwaysDelete: {
			type: Boolean,
			default: false
		},
		startMomentIndex: {
			type: Number,
			default: null
		},
		planningAndInvoicesData: {
			type: Object,
			default: null
		}
	},
	validations: {
		invoiceItems: {
			$each: {
				costType: { required },
				priceBeforeTax: { required, minValue: minValue(0) },
				vatCountry: { required },
				vatPercentage: {
					between: between(0, 100),
					// eslint-disable-next-line func-names, arrow function doesnt work here with vuelidate
					required: requiredIf(function () {
						return this.isManualOrApiCourse
					})
				},
				vatPercentageOptions: {},
				vatAmount: { required },
				vatExemptAmount: { required, minValue: minValue(0) },
				vatExemptAmountVAT: { required, minValue: minValue(0) }
			}
		}
	},
	data() {
		return {
			PROVIDER,
			costTypes: [],
			countries: [],
			countryOptions: [],
			vatExemption: {
				value: true,
				labelText: 'Ja'
			},
			counterToPreventInfiniteLoop: 0,
			enableVatFields: false,
			invoiceItems: []
		}
	},
	computed: {
		...mapState('providerModule', ['provider']),  
		setInvoices() {
			if(this.planningAndInvoicesData) { 
				let setInvoiceItems = null 
	
				if (this.startMomentIndex === null) {
					setInvoiceItems = this.planningAndInvoicesData?.learningMethod?.costs?.details[0]?.invoiceItems
				} 
				else {
					setInvoiceItems = this.planningAndInvoicesData?.learningMethod?.planning?.startMoments[this.startMomentIndex]?.invoiceItems
				}
	
				return setInvoiceItems
			} 

			return []
		},
		isManualOrApiCourse() {
			return this.planningAndInvoicesData?.source === 'Manual' || this.planningAndInvoicesData?.source === 'API'
		},
		isVatExempt() {
			return this.vatExemption.value && this.provider.isVatExempt
		},
		getTotalPricesBeforeTax() {
			if (this.invoiceItems && this.invoiceItems.length > 0) {
				return this.invoiceItems.reduce((accumulator, invoice) => accumulator + invoice.priceBeforeTax, 0)
			}

			return 0
		},
		getTotalPricesInludingTax() {
			if (this.invoiceItems && this.invoiceItems.length > 0) {
				return this.invoiceItems.reduce((accumulator, invoice) => {
					return accumulator + (invoice.priceBeforeTax + Number(invoice.vatAmount))
				}, 0)
			}

			return 0
		},
		getTotalTaxes() {
			if (this.invoiceItems && this.invoiceItems.length > 0 && this.vatExemption.value) {
				return this.invoiceItems.reduce((accumulator, invoice) => {
					return accumulator + invoice.vatExemptAmount + invoice.vatExemptAmountVAT
				}, 0)
			}

			return 0
		}
	},
	deactivated() {
	// called when removed from the DOM into the cache
	// and also when unmounted 
		if (this.invoiceItems) {
			this.removeEmptyInvoiceItem()
		}
	},
	mounted() {
		if (this.$route.name === 'course-create' || this.$route.name === 'course-duplicate') { 
			this.getCountryListAndSetCountryTaxRates()
		}
		
		this.invoiceItems = this.setInvoices
		this.getCostTypes()	 
    },
	watch: { 
		setInvoices: {
			handler() {
				this.invoiceItems = this.setInvoices
				this.counterToPreventInfiniteLoop += 1 

				if(this.invoiceItems && this.invoiceItems.length > 0 && this.invoiceItems !== undefined) {		

					if(this.counterToPreventInfiniteLoop === 1) { 
						this.getCountryListAndSetCountryTaxRates()
					}
				}
			},
			deep: true
		}
	},
	methods: { 
		removeEmptyInvoiceItem() {
            if (!this.invoiceItems) return   

			this.invoiceItems.forEach((invoiceItem, invoiceIndex) => {
				if(invoiceItem.costType === null) {
					this.invoiceItems.splice(invoiceIndex, 1) 
				}
			})
        },  
		async getCountryListAndSetCountryTaxRates() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, 
					API_CALL_URL_PATHS.countries, 
					1, 
					99999, 
					'sortOrder, name',
					null, 
					false
				)
				if (!response) return 
				this.countries = response.items 
				this.setCountryOptions()   
				
			} catch (error) {
				console.error('Something went wrong while retrieving country list', error)
			}
		},
		setCountryOptions() {  
			this.countries.reduce((acc, country) => {
				if (country) {
					this.countryOptions.push({
						displayText: country.displayName ? country.displayName : country.name,
						value: country.codeAlpha2
					})
				}
				return acc
			}, [])

			this.setVatPercentageOptions() 
		},
		async setVatPercentageOptions() {
			if (this.$route.name === 'course-create') {
				this.setCountryAndCountryTaxRates(this.invoiceItems[0], false)  
				return 
			}
			
			if (this.invoiceItems && this.invoiceItems.length > 0) { 
				this.invoiceItems.forEach((invoiceItem) => {
					this.setCountryAndCountryTaxRates(invoiceItem, false)
				})
			}
		},
		getSelectedCountryId(selectedCountry) {			
			if (this.countries.length === 0 || !selectedCountry) return null
			return this.countries.find((country) => country.codeAlpha2 === selectedCountry).id
		},
		async setCountryAndCountryTaxRates(invoiceItem, resetVatPercentage) {   
			const countryId = this.getSelectedCountryId(invoiceItem.vatCountry)
			const expandedProperty = 'taxRates'

			if (!countryId) return   
 
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.countries, countryId, expandedProperty, false)
				if (!response) return 
 
				const localTaxRates = response.taxRates.map((taxRateItem) => {
					return taxRateItem.taxRate
				})   
 
				this.setCountryAndVatPercentageOptions(invoiceItem, localTaxRates, resetVatPercentage)
  
			} catch (error) {
				console.error('Something went wrong while retrieving country details', error)
			}			 
		 },
		 setCountryAndVatPercentageOptions(invoiceItem, localTaxRates, resetVatPercentage) {
			if (invoiceItem && invoiceItem.vatCountry) {
				// reset vatPercentageOptions array & vat percentage when country value changes
				if (resetVatPercentage) {
					invoiceItem.vatPercentage = null
				}
				invoiceItem.vatPercentageOptions = []  

				// based on the selected country, recreate country-specific vatPercentageOptions for the BTW select dropdown in invoiceItem component
				localTaxRates.reduce((acc, taxRate) => {
					if (taxRate !== null) {
						const newOption = {
							displayText: `${taxRate}%`,
							value: taxRate
						}

						invoiceItem.vatPercentageOptions.push(newOption)  
					}
					return acc
				}, [])
			} 

			if(localTaxRates.length > 0) {
				invoiceItem.displayVatPercentageOptions = true	
				this.enableVatFields = true

				return
			}

			invoiceItem.displayVatPercentageOptions = false
			
		},
		populateVatPercentages(invoiceIndex, invoiceItem, selectedCountry) {  
			if (invoiceItem.vatCountry === selectedCountry) return

			invoiceItem.vatCountry = selectedCountry

			if (invoiceItem.vatCountry) {
				this.setCountryAndCountryTaxRates(invoiceItem, true)
			}  

			this.$v.invoiceItems.$each[invoiceIndex].vatCountry.$touch()
		},
		canInvoicePropertyNotBeValidated(invoiceProperty, invoiceIndex) {
			return !this.$v || !this.$v.invoiceItems || !this.$v.invoiceItems.$each || !this.$v.invoiceItems.$each[invoiceIndex] || !this.$v.invoiceItems.$each[invoiceIndex][invoiceProperty] 
		}, 
		costTypeError(invoiceIndex) {
			return isRequiredErrorMessage('costType', 'Investering', this.$v.invoiceItems.$each[invoiceIndex].costType )
		},
		priceBeforeTaxError(invoiceIndex) {
			if (!this.$v.invoiceItems.$each[invoiceIndex].priceBeforeTax.$error) return null
			if (!this.$v.invoiceItems.$each[invoiceIndex].priceBeforeTax.minValue) {
				return priceBeforeTaxMinValue
			}

			return isRequiredErrorMessage('priceBeforeTax', 'Prijs', this.$v.invoiceItems.$each[invoiceIndex].priceBeforeTax )
		},
		vatCountryError(invoiceIndex) {
			return isRequiredErrorMessage('vatCountry', 'Land', this.$v.invoiceItems.$each[invoiceIndex].vatCountry )
		}, 
		vatPercentageError(invoiceIndex) {
			if (!this.$v.invoiceItems.$each[invoiceIndex].vatPercentage.$error) return null
			if (!this.$v.invoiceItems.$each[invoiceIndex].vatPercentage.between) {
				return vatPercentageMinMaxValue
			}

			return isRequiredErrorMessage('vatPercentage', 'BTW', this.$v.invoiceItems.$each[invoiceIndex].vatPercentage)
		},
		vatExemptAmountError(invoiceIndex) {
			if (!this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmount.$error) return null
			if (!this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmount.minValue) {
				return vatExemptAmountMinValue
			}
			return isRequiredErrorMessage('vatExemptAmount', 'Prijs BTW vrijgesteld', this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmount)
		},
		vatExemptAmountVatError(invoiceIndex) {
			if (!this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmountVAT.$error) return null
			if (!this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmountVAT.minValue) {
				return vatExemptAmountVatMinValue
			}
			return isRequiredErrorMessage('vatExemptAmountVAT', 'BTW bedrag BTW vrijgesteld', this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmountVAT)
		}, 
		async addInvoice() {  
			const emptyInvoice = this.invoiceItems.filter((invoiceItem) => invoiceItem.costType === null )
			const newEmptyInvoiceItem = {...emptyInvoiceItem}
			
			if(emptyInvoice.length === 1) {
				this.$v?.$touch() 
				
				const toastNotification = {
					type: 'error',
					message: requiredError('Type')
				}

				return this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})
			}   

			this.invoiceItems.push(newEmptyInvoiceItem)

			const lastInvoiceItem = this.invoiceItems[this.invoiceItems.length - 1]

			await this.getCountryListAndSetCountryTaxRates()
			await this.setCountryAndCountryTaxRates(lastInvoiceItem, false) 
		},
		removeInvoiceItem(invoiceIndex) {  
			this.invoiceItems.splice(invoiceIndex, 1)
		},
		enableInvoiceVat(newValue) {
			this.vatExemption.value = newValue

			if (this.vatExemption.value === true) {
				this.vatExemption.labelText = 'Ja'
			} else {
				this.vatExemption.labelText = 'Nee'

				this.invoiceItems.forEach((invoiceItem) => {
					invoiceItem.vatExemptAmountVAT = 0
					invoiceItem.vatExemptAmount = 0 
				})
			}
		},  
		setPriceExcludingTaxPerInvoice(invoiceItem) {
			if (invoiceItem && this.vatExemption.value) {
				return invoiceItem.vatExemptAmount + invoiceItem.vatExemptAmountVAT
			}
		}, 
		onVatExemptAmountVatChange(invoiceIndex) {
			if (this.$v.invoiceItems.$each[invoiceIndex] === undefined) return 
			this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmountVAT.$touch()
		},
		onVatExemptAmountChange(invoiceIndex) {
			if (this.canInvoicePropertyNotBeValidated('vatExemptAmount', invoiceIndex)) return  
			this.$v.invoiceItems.$each[invoiceIndex].vatExemptAmount.$touch()
		},
		setPriceIncludingTaxPerInvoice(invoiceItem) {
			if (invoiceItem) {
				return invoiceItem.priceBeforeTax + Number(invoiceItem.vatAmount)
			}
		},
		onCostTypeChange(invoiceIndex, newValue) {
			this.invoiceItems[invoiceIndex].costType = newValue  
			this.$v.invoiceItems.$each[invoiceIndex].costType.$touch()
		}, 
		onPriceWithoutBTWChange(invoiceIndex, invoiceItem) {
			if (this.canInvoicePropertyNotBeValidated('priceBeforeTax', invoiceIndex)) return
			 
			this.setVatAmount(invoiceItem) 
			this.$v.invoiceItems.$each[invoiceIndex].priceBeforeTax.$touch()
		},
		onVatPercentageChange(invoiceItem, newValue, invoiceIndex) { 
			invoiceItem.vatPercentage = newValue 
			this.$v.invoiceItems.$each[invoiceIndex].vatPercentage.$touch()
            this.setVatAmount(invoiceItem) 
		},
		setVatAmount(invoiceItem) {
			invoiceItem.vatAmount = ((invoiceItem.vatPercentage / 100) * invoiceItem.priceBeforeTax).toFixed(2).replace(/(\.0+|0+)$/, '')
		},	
		async getCostTypes() {
			try {
				const response = await getEnumList(
					process.env.VUE_APP_PROVIDER_API_URL, 
					API_CALL_URL_PATHS.enumerations, 
					'costType',
					null,
					null, 
					false
				)
				if (!response) return
				this.costTypes = response 
			} catch (error) {
				console.error('Something went wrong while retrieving cost type enum list', error)
			}
		}		
	}
}
</script>
